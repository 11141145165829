.document-selector {
  width: 100%;
  margin-top: 40px;

  h2 {
    font-size: 18px;
    font-weight: 500;
    color: var(--blue);
    font-family: BentonSans, sans-serif;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      border: 2px solid #d7d7d7;
      color: black;
      box-sizing: border-box;
      padding: 10px;
      margin-bottom: 9px;
      border-radius: 4px;
      text-align: center;
      background-color: white;
      cursor: pointer;
      outline: none;
      display: flex;
      position: relative;
      box-sizing: border-box;

      &:hover {
        background-color: rgb(248, 248, 248);
      }

      &.active {
        border: 2px solid #00e;
        background-color: white;
      }

      &.disabled,
      button:disabled,
      button[disabled] {
        background-color: #fbfbfb;
        color: #898989;
        border-color: #ebebeb;

        .document-icon img {
          opacity: 0.4;
        }

        .document-label::after {
          opacity: 0.3;
        }
      }

      .document-icon {
        background-color: #edeeef;
        border-radius: 56px;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        margin-right: 15px;

        img,
        svg {
          fill: #2c3e4f;
        }
      }

      .document-label {
        display: flex;
        justify-content: left;
        align-items: center;
        height: 56px;

        &::after {
          display: block;
          content: " ";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nOCcgaGVpZ2h0PScxMicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNLjMzMiAxLjgxMUw1LjI2MiA2bC00LjkzIDQuMTg5YTEuMDE0IDEuMDE0IDAgMDAwIDEuNSAxLjE5MiAxLjE5MiAwIDAwMS42MDQgMGw1LjczNi00Ljg3M0ExLjAxIDEuMDEgMCAwMDcuOTk3IDZhMS4wMSAxLjAxIDAgMDAtLjMyNS0uODE2TDEuOTM2LjMxYTEuMTkyIDEuMTkyIDAgMDAtMS42MDQgMCAxLjAxNCAxLjAxNCAwIDAwMCAxLjV6JyBmaWxsPScjMkMzRTRGJy8+PC9zdmc+");
          width: 8px;
          height: 12px;
          position: absolute;
          right: 10px;
        }
      }
    }
  }
}
